import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const EsGuidesIRuthra: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Subjugation - Vivienne</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_viv.png"
            alt="Ruthra - raid guide"
          />
        </div>
        <div className="page-details">
          <h1>Subjugation - Vivienne</h1>
          <h2>
            Struggling with the Subjugation - Vivienne? This guide will help
            you!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Vivienne Mechanics" />
        <h5>Stun bar</h5>
        <StaticImage
          src="../../../images/eversoul/viv/image4.webp"
          alt="Viv Sub"
        />
        <p>
          Just as in the Jacqueline subjugation, Vivi has a stun bar mechanic
          that makes her immune to all CC, but stuns (and only stuns) will bring
          this bar down. When the bar is depleted, Vivi will go into stun mode
          for a duration. It will also fully replenish all your characters’ mana
          bars and activate their ultimates, so spamming your abilities right
          before the bar is depleted, it is best to use up all your skills (of
          course, saving the ones that stun for last).
        </p>
        <p>
          Breaking her stun bar will also put a 100% received damage debuff on
          Vivi for the duration of the stun, making it the best time to use your
          best DPS ultimates.
        </p>
        <StaticImage
          src="../../../images/eversoul/viv/image3.webp"
          alt="Viv Sub"
        />
        <p>
          This stun bar mechanic is very important to making your life easier in
          subjugation, as the amount you remove from it in battle carries over
          from team to team. Keeping this in mind, you can use some “throwaway”
          stun teams in between your main DPS teams to deplete the bar prior to
          a fight. It’s also very important to make sure you don’t deplete it by
          accident in the last few seconds of a fight when you’ve already used
          up your good DPS ultimates, allowing it to carry over for your next
          team to start a fresh rotation.{' '}
        </p>
        <h5>Main skill</h5>
        <StaticImage
          src="../../../images/eversoul/viv/image2.webp"
          alt="Viv Sub"
        />
        <p>
          While this isn’t anything new, it is good to keep note of Vivi’s mana
          bar progress. When her bar is full she unleashes a large AoE nuke that
          does significant damage to your team and knocks them back. Making sure
          your team is healed up, and/or using defensive abilities right before
          the skill hits can save your run.{' '}
        </p>
        <p>The higher difficulty you take on, the more this skill will hurt.</p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Level</th>
              <th>Skill</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Basic</td>
              <td>
                The Witch who destroyed the world brings a violent storm,
                dealing 350% ATK damage to all of her enemies and knocking them
                back with enormous force.
              </td>
            </tr>
            <tr>
              <td>Lv. 200</td>
              <td> Deals additional 175% ATK damage.</td>
            </tr>
            <tr>
              <td>Lv. 300</td>
              <td>
                Causes the Laceration debuff which deals 75% ATK damage every 2
                seconds for 12 seconds.
              </td>
            </tr>
            <tr>
              <td>Lv. 400</td>
              <td>
                Enemies hit by “Destructive Storm” receive the “Imminent
                Destruction” debuff. “Imminent Destruction” can stack 3 times.
                Each stack has the following effects. 1 stack: Permanently
                decreases Magic Resistance by 30%. 2 stacks: Permanently
                decreases Speed by 20%. 3 stacks: Becomes incapacitated.
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>Tarnished Wisdom</h5>
        <StaticImage
          src="../../../images/eversoul/viv/image7.webp"
          alt="Viv Sub"
        />
        <p>
          The other skill of note in Vivi’s arsenal is her subskill Tarnished
          Wisdom. She will stand still and channel this skill, which deals
          damage over time, and provides her with mana. Because of this mana
          increase, she will tend to use her main skill right after this, which
          can be a pain for keeping your team alive. At higher levels, it also
          applies a DoT debuff that can be removed by using your main skill.{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/viv/image9.webp"
          alt="Viv Sub"
        />
        <h5>Storm of Doom</h5>
        <StaticImage
          src="../../../images/eversoul/viv/image13.webp"
          alt="Viv Sub"
        />
        <p>
          When Vivi’s HP reaches 20% she will go into an enrage mode, at which
          point she will stop moving and channel a map-wide AoE damage skill
          that ramps up over time. The stun mechanic does not work during this
          phase, and after a certain period of time, it also ignores
          invulnerability skills like Catherine’s subskill. Playing around the
          last 20% of her HP bar is imperative to winning this fight, but there
          are ways to make it easier, as will be discussed in this guide.{' '}
        </p>
        <SectionHeader title="Fight guide" />
        <p>
          The following is an explanation of how to make the fight easier, using
          examples from experience. This guide was made for clearing level 301,
          with a 242 level sync and average character ascensions/artifacts.
          Keeping this in mind, you may try higher/lower difficulties as your
          roster permits.
        </p>
        <h5>The first ~70%</h5>
        <p>
          Starting out the fight is relatively simple, as long as you keep your
          teams alive. The idea for the first 70% of her HP bar is to deplete
          her stun bar strategically, only breaking it when you can put out
          optimal DPS (your best ults and teams). With this in mind, you may
          even want to intentionally not break her bar at all throughout an
          entire team’s duration. In the example run, the first break was saved
          for team 2 so that the Lizelotte team could get 2 entire DPS phases.
          Your teams may vary, but the idea will remain the same.
        </p>
        <p>
          <strong>
            **Unless you have multiple copies of Petra, it is strongly
            recommended to not use her until the final team.
          </strong>
        </p>
        <h5>30% - 15%</h5>
        <p>
          This is the area where team building and stun bar optimization can
          become a struggle. Using the example teams, the ideal place to have
          her HP and stun bar is at about half stun and 30% HP. Depending on
          your team’s DPS you may need it to be higher or lower.{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/viv/image12.webp"
          alt="Viv Sub"
        />
        <p>
          There are different ways to attempt this, so I will try to list a few
          scenarios.
        </p>
        <ul>
          <li>
            Scenario 1: The team you use around this time can do decent DPS, and
            you time the stun to go off just before Vivi’s HP gets to 20%, at
            which point you throw out all the best DPS ults to try to bring her
            down as low as possible so that your final team can finish her last
            remaining HP off while the storm is up.
          </li>
          <li>
            Scenario 2: You use a stun filler team here that can deal a small
            amount of damage, and whittle down the stun bar to one hit. This
            team must NOT bring her HP to 20%, or your next team will start in
            the storm with no way to stun, but must do enough damage so that
            your next team starts approximately at 20% when the stun goes off.
            Complicated to pull off, but may be necessary if your DPS is low.{' '}
          </li>
          <li>
            Scenario 3 (Not recommended): Save your Lize/best DPS team for last,
            kill her stun bar just before she hits 20% HP, and kill her in stun
            phase before she goes berserk. Timing this is not fun, definitely
            not recommended unless you enjoy malding
          </li>
        </ul>
        <h5>The last 20%</h5>
        <p>
          This is where the Petra magic comes in. Petra has a beautiful reflect
          damage subskill that reflects damage she takes back at the dealer
          after 18 seconds, so the idea here is to ensure Petra lives long
          enough to do this. Depending on where you ended off your stun/HP bars
          previously, you can either:
        </p>
        <ul>
          <li>
            Stun bar 1-2 hits off: Bring a full undead team and stun her before
            she hits 20% using Erusha/Jacq, then do the usual Violette DoT nuke
            thing during the stun phase, hopefully doing enough damage that
            Petra can finish her off once the rest of your team dies when she
            comes out of stun,
          </li>
          <li>
            HP below 15%: Bring a full sustain team that includes Catherine,
            anything that keeps Petra alive until she gets her reflect subskill
            off, at which point Vivi’s HP bar will cease to exis
          </li>
        </ul>
        <SectionHeader title="Example teams" />
        <h5>Fairy</h5>
        <p>Mid-tier DPS and lots of stun bar manipulation. </p>
        <StaticImage
          src="../../../images/eversoul/viv/image8.webp"
          alt="Viv Sub"
        />
        <h5>Lizelotte + Beasts</h5>
        <p>Big thicc DPS, save your stuns for this one.</p>
        <StaticImage
          src="../../../images/eversoul/viv/image5.webp"
          alt="Viv Sub"
        />
        <h5>Human</h5>
        <p>
          Stuns and DPS, great for setting up before your last DPS phase, and
          either leaving the stun or using it to get her down to sub-20% HP
        </p>
        <StaticImage
          src="../../../images/eversoul/viv/image11.webp"
          alt="Viv Sub"
        />
        <h5>Petra + Sustain</h5>
        <p>
          {' '}
          For when you’ve gotten the HP quite low, keep Petra alive until she
          nukes Vivi with her own damage.
        </p>
        <StaticImage
          src="../../../images/eversoul/viv/image1.webp"
          alt="Viv Sub"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesIRuthra;

export const Head: React.FC = () => (
  <Seo
    title="Subjugation - Vivienne | Eversoul | Prydwen Institute"
    description="Struggling with the Subjugation - Vivienne? This guide will help you!"
    game="eversoul"
  />
);
